/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type NavigationBar_user = {
    readonly hasDuellingAvailable: boolean;
    readonly hasDuellingEnabled: boolean;
    readonly isOnboarding: boolean;
    readonly hideProfilePage: boolean;
    readonly " $refType": "NavigationBar_user";
};
export type NavigationBar_user$data = NavigationBar_user;
export type NavigationBar_user$key = {
    readonly " $data"?: NavigationBar_user$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"NavigationBar_user">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NavigationBar_user",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasDuellingAvailable",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hasDuellingEnabled",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isOnboarding",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hideProfilePage",
            "storageKey": null
        }
    ],
    "type": "AuthenticatedUser",
    "abstractKey": null
} as any;
(node as any).hash = '4bfd7ef77923af9d31f2865c476fc3f5';
export default node;
