/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type LoginType = "LTI" | "SAML" | "WEB";
export type RoutingUserQueryVariables = {};
export type RoutingUserQueryResponse = {
    readonly me: {
        readonly firstLogin: boolean;
        readonly idp: string | null;
        readonly isOnboarding: boolean;
        readonly loginType: LoginType;
        readonly " $fragmentRefs": FragmentRefs<"NavigationBar_user" | "RoutingAppRoutes_user">;
    } | null;
};
export type RoutingUserQuery = {
    readonly response: RoutingUserQueryResponse;
    readonly variables: RoutingUserQueryVariables;
};



/*
query RoutingUserQuery {
  me {
    firstLogin
    idp
    isOnboarding
    loginType
    ...NavigationBar_user
    ...RoutingAppRoutes_user
    id
  }
}

fragment NavigationBar_user on AuthenticatedUser {
  hasDuellingAvailable
  hasDuellingEnabled
  isOnboarding
  hideProfilePage
}

fragment RoutingAppRoutes_user on AuthenticatedUser {
  isOnboarding
  ...RoutingOnlyGuests_user
  ...RoutingRequireAuthentication_user
}

fragment RoutingOnlyGuests_user on AuthenticatedUser {
  loginType
}

fragment RoutingRequireAuthentication_user on AuthenticatedUser {
  isOnboarding
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "firstLogin",
        "storageKey": null
    } as any, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "idp",
        "storageKey": null
    } as any, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isOnboarding",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "loginType",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "RoutingUserQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuthenticatedUser",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "NavigationBar_user"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "RoutingAppRoutes_user"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "RoutingUserQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "AuthenticatedUser",
                    "kind": "LinkedField",
                    "name": "me",
                    "plural": false,
                    "selections": [
                        (v0 /*: any*/),
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasDuellingAvailable",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasDuellingEnabled",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hideProfilePage",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "bfc170431f6419ac8ac88e03966efbdc",
            "id": null,
            "metadata": {},
            "name": "RoutingUserQuery",
            "operationKind": "query",
            "text": "query RoutingUserQuery {\n  me {\n    firstLogin\n    idp\n    isOnboarding\n    loginType\n    ...NavigationBar_user\n    ...RoutingAppRoutes_user\n    id\n  }\n}\n\nfragment NavigationBar_user on AuthenticatedUser {\n  hasDuellingAvailable\n  hasDuellingEnabled\n  isOnboarding\n  hideProfilePage\n}\n\nfragment RoutingAppRoutes_user on AuthenticatedUser {\n  isOnboarding\n  ...RoutingOnlyGuests_user\n  ...RoutingRequireAuthentication_user\n}\n\nfragment RoutingOnlyGuests_user on AuthenticatedUser {\n  loginType\n}\n\nfragment RoutingRequireAuthentication_user on AuthenticatedUser {\n  isOnboarding\n}\n"
        }
    } as any;
})();
(node as any).hash = '35b4eee8958f96f167526d2ff5d8b4e8';
export default node;
